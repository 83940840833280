<template>
  <div class="container py-5">
    <p class="fw-bold">2022 WRG祛斑抗衰高峰巡迴論壇 (武漢站)</p>
    <p>時間：2022 09/27-28</p>
    <p>地點：武漢 ‧ 萬科君瀾酒店</p>
    <p>
     WRG（WHITENING AND REJUVENATION GROUP）祛斑抗衰聯盟是由一批長期工作在臨床一線的實操型醫美專家組成的學術聯盟，旨在運用聯盟成員的集體智慧，研究解決美白祛斑和抗衰年輕化的整合治療方案，力求效果最大化和副反應最小化。
    <br><br>
    此次高峰巡迴論壇邀請業內大咖聚焦輕醫美的發展走向，並進行多場課題分享。對當今形勢下輕醫美市場的運營思路和對對來走向的研判，前瞻洞察國內祛斑美膚抗衰的新思維、新走向、新技術。
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_09.jpg"
    />
  </div>
</template>
<script>
export default {};
</script>
